import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { take } from 'rxjs/operators';
import {ProjectObject} from '../models/project-object.model';
import {ProductService} from '../../shared/services/product.service';
import {GroupByType} from '../../shared/enums/group-by-type.enum';
import {defaultProjectObject} from '../config/default-project-object.model';

@Injectable()
export class HomeResolver {
  constructor(private productService: ProductService) {}

  async resolve(route: ActivatedRouteSnapshot): Promise<ProjectObject[]> {
    const projectsSearch: Partial<ProjectObject> = { ...defaultProjectObject , ItemsPerPage: 1000000 , GroupBy: GroupByType.None };
    const result = await this.productService.GetProjectsList(projectsSearch).toPromise();
    if (result.Result) {
      return result.ResultData as ProjectObject[];
    }
    return [];
  }
}
