import {ProjectObject} from '../models/project-object.model';
import {defaultDateObject} from '../../shared/configs/default-date-object.model';
import {defaultLookupObject} from '../../shared/configs/default-lookup-object.model';

export const defaultProjectObject: Partial<ProjectObject> = {
  ProjectId: -1,
  Category: defaultLookupObject,
  ProjectDurationType: defaultLookupObject,
  ProjectPfId: '',
  ShowInSite: true,
  CreatedDate: defaultDateObject,
  ModifiedDate: defaultDateObject,
  CreatedToDate: defaultDateObject,
  ModifiedFromDate: defaultDateObject,
  ModifiedToDate: defaultDateObject,
  CreatedBy: -1,
  ModifiedBy: -1,
  ItemsPerPage : 50,
  PageNumber : 0,
  GroupBy : '',
  IncludeTotalRowsLength : false,
};


