import {Component, Input} from '@angular/core';
import {ImagesGallerySlider} from '../../../shared/data/slider';

@Component({
  selector: 'app-images-gallery',
  templateUrl: './images-gallery.component.html',
  styleUrl: './images-gallery.component.scss'
})
export class ImagesGalleryComponent {

  constructor() {
  }

  @Input() images: any[] = [];

  public ImagesGallerySliderConfig: any = ImagesGallerySlider;

  ngOnInit(): void {
  }
}
