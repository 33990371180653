<header class="sticky" [ngClass]="class" [class.fixed]="stick && sticky">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="main-menu">
          <div class="menu-left">
            <div class="brand-logo">
              <a routerLink="/" id="headerlogo">
                <img [src]="themeLogo" class="img-fluid" alt="logo">
              </a>
            </div>
          </div>
          <div class="menu-right pull-right">
            <div>
              <app-menu></app-menu>
            </div>
          </div>
          <div class="social-nav">
            <a><img src="/assets/images/icon/instagram.png" style="width: 40px;height: 40px; margin-left: 15px; cursor: pointer" class="img-fluid"></a>
            <a><img src="/assets/images/icon/facebook.png" style="width: 40px;height: 40px;cursor: pointer " class="img-fluid"></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
