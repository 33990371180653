import {FieldControl} from '../models/field-control.model';

export const defaultFieldControl: FieldControl  = {
  fCType: 'hint',
  isValid: true,
  hintMessage: '',
  errorMessage: ''
};

export const defaultNoImage = '/assets/images/no-image.png';

