<!--footer section -->
<footer [class]="class" class="footer-back">
	<section class="section-b-space light-layout">
		<div class="container">
			<div class="row footer-theme">
				<div class="col-lg-12 col-md-12">
					<div class="footer-contant">
						<div class="footer-logo text-center">
							<img [src]="themeLogo" alt="logo">
						</div>
                        <div class="social-nav text-center">
                            <a><img src="/assets/images/icon/instagram.png" style="width: 40px;height: 40px; margin-left: 15px; cursor: pointer" class="img-fluid"></a>
                            <a><img src="/assets/images/icon/facebook.png" style="width: 40px;height: 40px;cursor: pointer " class="img-fluid"></a>
                        </div>
					</div>
				</div>
			</div>
		</div>
	</section>
	<div class="sub-footer darker-subfooter">
		<div class="container">
			<div class="row">
                <div class="col-xl-12 col-md-12 col-sm-12">
                  <div class="footer-end">
                      <h4 class="text-center">DAR AL SALAM</h4>
                    <p class="text-center"> جميع حقوق النشر محفوظة <i class="fa fa-copyright" aria-hidden="true"></i> {{ today | date:'y'}}</p>
                  </div>
                </div>
            </div>
		</div>
	</div>
</footer>
<!--footer section end -->
