import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {FashionOneComponent} from './home/fashion/fashion-one/fashion-one.component';
import {HomeResolver} from './home/resolvers/home.resolver';

const routes: Routes = [
  {
    path: '',
    component: FashionOneComponent,
    pathMatch: 'full',
    resolve: {
      siteData: HomeResolver
    }
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
  },
  {
    path: '**', // Navigate to Home Page if not found any page
    redirectTo: '',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: false,
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
