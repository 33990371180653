<app-header-one [sticky]="true"></app-header-one>

<!-- Home slider start-->
<section class="p-0">
  <app-slider 
    [sliders]="sliders" 
    [textClass]="'text-center'"
    [category]="'fashion'">
  </app-slider>
</section>
<!-- Home slider End -->

<!-- collection banner start-->
<section class="pb-0">
  <div class="small-container">
    <div class="row partition2">
      <div class="col-md-6" *ngFor="let collection of availableProjects" [ngClass]="{'project-unavailable' : !collection.IsActive}">
        <a  [ngStyle]="!collection.IsActive ? {'pointer-events': 'none'} : {}" href="https://questionnaire.thedarsalam.com/questionnaire/{{collection.ProjectPfId}}" target="_self">
          <div class="collection-banner p-center text-center m-b-40">
            <div class="contain-banner" [ngStyle]="collection.Category.LookUpId ===3 ? {'background-image': 'url(' + collection.ProjectBackgroundImage + ')'} : {}">
              <div *ngIf="collection.Category.LookUpId === 1 || collection.Category.LookUpId === 4">
                <img [src]="collection.ProjectLogo" style="width: 130px;" class="img-fluid" alt="collection-banner">
              </div>
              <div *ngIf="collection.Category.LookUpId !== 1 && collection.Category.LookUpId !== 2 && collection.Category.LookUpId !== 3 && collection.Category.LookUpId !== 4">
                <h4>{{collection.FirstProjectName}}</h4>
                <h2 class="m-t-10">{{collection.LastProjectName}}</h2>
              </div>
              <div *ngIf="collection.Category.LookUpId == 2" style="display: flex; flex-direction: row; width: 100%;">
                  <div>
                    <h2 class="m-t-10" style="text-align: right;">{{collection.ProjectName}}</h2>
                  </div>
                  <div>
                    <div class="image-back" style="background-repeat: no-repeat; background-size: contain; position: absolute; left: 0;  top: 5px;" [ngStyle]="collection.Category.LookUpId ===2 ? {'background-image': 'url(' + collection.ProjectBackgroundImage + ')'} : {}">
                      <h2 class="m-t-10" style="text-align: right; visibility: hidden">{{collection.LastProjectName}}</h2>
                    </div>
                  </div>
              </div>
              <div *ngIf="collection.Category.LookUpId == 3" style="display: flex; flex-direction: row; width: 100%;">
                <div>
                  <h2 class="m-t-10" style="text-align: right;">{{collection.ProjectName}}</h2>
                </div>
                <div>
                  <div class="image-back" style="background-repeat: no-repeat; background-size: contain; position: absolute; left: 10px;  top: 5px;" [ngStyle]="collection.Category.LookUpId ===3 ? {'background-image': 'url(' + collection.ProjectLogo + ')'} : {}">
                    <h2 class="m-t-10" style="text-align: right; visibility: hidden">{{collection.LastProjectName}}</h2>
                  </div>
                </div>
              </div>
              <div class="w-100 m-t-20">
                <p>{{collection.Notes}}</p>
              </div>
              <div class="w-100">
                <a class="btn btn-solid large-text w-100 radius-50">{{ collection.Category.LookUpId === 4 ? 'سجلوا للتطوع' : 'سجلوا الان' }}</a>
              </div>

            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
</section>
<!-- collection banner End -->

<!-- Product slider start-->
<div class="title1 section-t-space">
<!--  <h4>special offer</h4>-->
  <img src="assets/images/icon/check.png" class="img-fluid" alt="our projects">
  <h2 class="title-inner1 m-t-10">مشاريعنا</h2>
</div>
<section class="section-b-space p-t-0">
  <div>
    <div class="row">
      <div class="col">
        <app-images-gallery [images]="gallery"></app-images-gallery>
      </div>
    </div>
  </div>
</section>
<!-- Product slider End -->

<!-- Parallax banner start-->
<section class="p-0 position-relative">
  <div class="title-left">
    <!--  <h4>special offer</h4>-->
    <h2 class="title-inner1 m-t-10">من نحن</h2>
  </div>
  <div class="full-banner parallax-banner1 parallax text-center overlay-container"
    [ngStyle]="{'background-image': 'url(assets/images/gallery/1.jpg)'}">
    <div class="container">
      <div class="row">
        <div class="col">
          <div class="banner-contain">
            <img src="assets/images/icon/multiple-users.png" class="img-fluid" alt="multiple users icon">
            <p>جمعية دار السلام- من أجل سد الفجوات في المجتمع</p>
            <p>עמותת דאר אלסלאם - לגישור על פערים בחברה</p>
            <p>جمعية دار السلام هي جمعية خيرية محلية تقدم خدماتها لسكان مدينة كفر قاسم وهدفها تقليل الفجوات بين أبناء المجتمع</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Parallax banner End -->

<app-footer-one [newsletter]="false"></app-footer-one>

