<owl-carousel-o [options]="ImagesGallerySliderConfig">
    <ng-container *ngFor="let item of images; let i = index">
        <ng-template carouselSlide>
            <div>
                <div class="images-gallery-block">
                    <a>
                        <img  [src]="item.image" style="height: 250px" alt="logo" class="p-r-l-15">
                    </a>
                </div>
            </div>
        </ng-template>
    </ng-container>
</owl-carousel-o>
