import {LookUpFieldsControls, LookUpObject} from '../models/lookup-object.model';
import {defaultFieldControl} from './default-field-control.model';

export const defaultLookUpFieldsControls: LookUpFieldsControls = {
  LookUpId: defaultFieldControl,
  LookUpName: defaultFieldControl,
  LookUpCode: defaultFieldControl,
  Param1: defaultFieldControl,
};

export const defaultLookupObject: LookUpObject = {
  LookUpId: -1,
  LookUpIds : '',
  LookUpIdsList: [],
  LookUpTableName : '',
  LookUpName : '',
  LookUpCode : '',
  Param1 : '',
  Param2 : '',
  Param3 : '',
  LookUpData : null,
  LookUpStatusId : -1,
  LookUpStatusName : '',
  IsActive : true,
  IsSelectedByDefault : false,
  CreatedDateStr : '',
  ModifiedDateStr : '',
  CreatedBy : -1,
  ModifiedBy : -1,
  FieldsControls: defaultLookUpFieldsControls,
};


