import {DateObject} from '../models/date-object.model';

export const defaultDateObject: DateObject = {
  MomentDate: undefined,
  FullDate: undefined,
  ShortDate:  '',
  ShortTime: '',
  SearchDate:  '',
  FromDate:  '',
  ToDate:  '',
};
