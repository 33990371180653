import { Component, OnInit } from '@angular/core';
import { ProductSlider } from '../../../shared/data/slider';
import { Product } from '../../../shared/classes/product';
import { ProductService } from '../../../shared/services/product.service';
import {ActivatedRoute} from '@angular/router';
import {ProjectObject} from '../../models/project-object.model';

@Component({
  selector: 'app-fashion-one',
  templateUrl: './fashion-one.component.html',
  styleUrls: ['./fashion-one.component.scss']
})
export class FashionOneComponent implements OnInit {

  public products: Product[] = [];
  public productCollections: any[] = [];
  public active;
  availableProjects: ProjectObject[];

  constructor(public productService: ProductService, private route: ActivatedRoute) {}

  public ProductSliderConfig: any = ProductSlider;

  public sliders = [{
    title: 'سد الفجوات',
    subTitle: 'بين أبناء المجتمع',
    image: 'assets/images/slider/1.png'
  }];

  // Collection banner
  public collections = [{
    image: 'assets/images/collection/fashion/1.jpg',
    title: 'مشروع',
    desc: 'مشروع رمضاني يتم توزيع قسائم شراء من مصالح تجارية قسماوية لدعم إقتصاد المدينة ولمساعدة العائلات المستورة',
    title2: 'زاد الخير',
    button: 'سجلوا الان',
  }, {
    image: 'assets/images/collection/fashion/2.jpg',
    title: 'مشروع',
    desc: 'يقام إستعداداً للعودة إلى المدارس للأطفال من اجل دعم ومساعدة الطلاب من الأسر المستورة',
    title2: 'الحقيبة المدرسية',
    button: 'سجلوا الان',
  },
    {
      image: 'assets/images/collection/fashion/3.jpg',
      title: 'مشروع',
      desc: 'يوفر هذا المشروع المنح الدراسية المستمرة للطلاب الجامعيين من العائلات المستورة',
      title2: 'المنح الجامعية',
      button: 'سجلوا الان',
    }, {
      image: 'assets/images/collection/fashion/4.jpg',
      title: 'عيادة',
      desc: 'عيادة طبية تقدم خدمات مجانية يديرها ويفعلها بشكل تطوعي أطباء وممرضين من أبناء كفر قاسم',
      title2: 'دار السلام',
      button: 'سجلوا للتطوع',
    }
  ];

  // Blog
  public blog = [{
    image: 'assets/images/blog/1.jpg',
    date: '25 January 2018',
    title: 'Lorem ipsum dolor sit consectetur adipiscing elit,',
    by: 'John Dio'
  }, {
    image: 'assets/images/blog/2.jpg',
    date: '26 January 2018',
    title: 'Lorem ipsum dolor sit consectetur adipiscing elit,',
    by: 'John Dio'
  }, {
    image: 'assets/images/blog/3.jpg',
    date: '27 January 2018',
    title: 'Lorem ipsum dolor sit consectetur adipiscing elit,',
    by: 'John Dio'
  }, {
    image: 'assets/images/blog/4.jpg',
    date: '28 January 2018',
    title: 'Lorem ipsum dolor sit consectetur adipiscing elit,',
    by: 'John Dio'
  }];

  // Logo
  public logo = [{
    image: 'assets/images/logos/1.png',
  }, {
    image: 'assets/images/logos/2.png',
  }, {
    image: 'assets/images/logos/3.png',
  }, {
    image: 'assets/images/logos/4.png',
  }, {
    image: 'assets/images/logos/5.png',
  }, {
    image: 'assets/images/logos/6.png',
  }, {
    image: 'assets/images/logos/7.png',
  }, {
    image: 'assets/images/logos/8.png',
  }];

  // Gallery
  public gallery = [{
    image: 'assets/images/gallery/1.jpg',
  }, {
    image: 'assets/images/gallery/2.jpg',
  }, {
    image: 'assets/images/gallery/3.jpg',
  }, {
    image: 'assets/images/gallery/4.jpg',
  }, {
    image: 'assets/images/gallery/5.jpg',
  }, {
    image: 'assets/images/gallery/6.jpg',
  }, {
    image: 'assets/images/gallery/7.jpg',
  }, {
    image: 'assets/images/gallery/8.jpg',
  }, {
    image: 'assets/images/gallery/9.jpg',
  }];

  ngOnInit(): void {
    this.availableProjects = this.route.snapshot.data.siteData;
  }

  // Product Tab collection
  getCollectionProducts(collection) {
    return this.products.filter((item) => {
      if (item.collection.find(i => i === collection)) {
        return item;
      }
    });
  }

}
