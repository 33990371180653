export enum GroupByType {
  None = 'None',
  Name = 'Name',
  Date = 'Date',
  Customer = 'Customer',
  Car = 'Car',
  OrderId = 'OrderId',
  TransactionType = 'TransactionType',
  Supplier = 'Supplier',
}
